<template>
    <div>
        <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
            <b-spinner variant="primary" label="Text Centered" />
        </div>
        <div v-else>
            <table-address :addresses="addresses" @editForm="editForm" @refresh="refresh" />
            <modal-address @refresh="refresh" />
            <modal-address-edit :id="id" @refresh="refresh" />
        </div>
        <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
<script>
import tableAddress from "@/views/component/Table/tableAddress.vue"
import modalAddress from "@/views/component/Modal/ModalAddress/modalAddress.vue"
import modalAddressEdit from "@/views/component/Modal/ModalAddress/modalAddressEdit.vue"
import filterModalVue from "@/views/component/FilterComponents/filterModal.vue"
export default {
    components: {
        tableAddress,
        modalAddress,
        modalAddressEdit,
        filterModalVue
    },
    data(){
        return {
            id: '',
            addresses: [],
            showPreloader: false,
            fields: [ 
                { key: 'id', label: 'Id'},
                { key: 'name', label: '№ дома'},
                { key: 'addres_street', label: 'Улица'},
                { key: 'district', label: 'Район'},
                { key: 'village', label: 'Населённый пункт'},
                { key: 'city', label: 'Город'},
                { key: 'region', label: 'Регион'},
                { key: 'home_type', label: 'Тип адреса'},
                { key: 'street_type', label: 'Тип улицы'},
                { key: 'is_active', label: 'Статус'},
            ],
        }
    },
    mounted(){
        this.$store.commit('pageData/setdataCount', null)
        this.openFilter()
    },
    methods: {
        editForm(id){
            this.id = id
            setTimeout(this.openModal, 0);
        },
        openModal(){
            this.$bvModal.show('addressModalEdit')
        },
        openFilter(){
            let allRoutes = this.$store.state.draggableTab.tabs
            let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
            let arrayRoutes = []
            allRoutes.forEach(element => {
                arrayRoutes.push(element.path)
            });
            if (arrayRoutes.includes(this.$route.path) == true) {
                this.showPreloader = true
                this.$http
                    .get(`${this.$route.name}`, {params: param}).then(res => {
                        this.addresses = res.data
                        this.$store.commit('pageData/setdataCount', this.addresses.length)
                        this.showPreloader = false
                        this.$store.commit('REFRESH_DATA', false)
                    })
            }else if(arrayRoutes.includes(this.$route.path) == false){
                this.$bvModal.show(this.$route.name + 'filter')
            }
        },
        refresh(){
            this.showPreloader = true
            this.$http
                .get('addresses/addresses').then(res => {
                    this.addresses = res.data
                    this.$store.commit('pageData/setdataCount', this.addresses.length)
                    this.showPreloader = false
                    this.$store.commit('REFRESH_DATA', false)
                })
        },

        sendToParent(tableData){
            this.addresses = tableData
            this.$store.commit('pageData/setdataCount', this.addresses.length)
        },
    },
    computed: {
        fetchingNewData(){
            return this.$store.state.refresh.fetchingNewData
        }
    },
    watch: {
        fetchingNewData(val){
            if (val){
                this.$http
                .get(`${this.$route.name}`)
                .then(res =>{
                    this.addresses = res.data
                    this.$store.commit('pageData/setdataCount', this.addresses.length)
                    this.$store.commit('REFRESH_DATA', false)
                })
            }
        },
        divisions(newValue) {
            console.log(newValue, 'fwef');
            this.$forceUpdate()
        }
    }
}
</script>