<template>
    <b-modal id="addressModalEdit" title="Изменить данные адреса" size="md" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened" @close="btnCancel">
        <b-row>
            <b-col cols="12">
                 <b-row>
                    <b-col cols="6">
                        <label>Ваш адрес</label>
                        <b-input v-model="address.name" placeholder="Адрес" />
                    </b-col>
                    <b-col cols="6">
                        <label>Ваша улица</label>
                        <v-select
                            v-model="address.street_id"
                            placeholder="Улица"
                            label="name"
                            :options="streets"
                            class="select-size-md"
                            @search="searchStreets"
                        />
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col cols="6">
                        <label>Долгота</label>
                        <b-input v-model="address.lng" placeholder="Долгота" />
                    </b-col>
                    <b-col cols="6">
                        <label>Ширина</label>
                        <b-input v-model="address.lat" placeholder="Широта" />
                    </b-col>
                    <b-col cols="6" class="mt-2">
                        <label>Тип вашего дома</label>
                        <v-select
                            v-model="address.home_type_id"
                            placeholder="Тип дома"
                            :options="home_types"
                            label="name"
                            :reduce="option => option.id"
                        />
                    </b-col>
                    <b-col cols="6" class="ml-auto mt-2">
                        <b-button class="mt-2" @click="$bvModal.show('mapVue')" size="md" variant="outline-primary" style="width: 215px;" >
                            <feather-icon icon="MapPinIcon" />
                                <span class="ml-1">Выбрать на карте</span>
                        </b-button>
                     </b-col>
                     <b-col cols="12">
                        <b-form-checkbox class="mt-2" v-model="address.is_active" checked="true" name="check-button" switch inline>
                            {{this.address.is_active ? 'Активный' : ' Не активный'}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="d-flex justify-content-between">
                        <b-button
                        :disabled="submitButtonDisabled"
                        class="mt-2 col-md-5"
                        variant="primary"
                        @click="editAddress()">
                            Изменить
                        </b-button>
                        <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel">
                            Отмена
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <map-vue @latLng="latLng" :address="address"/>
    </b-modal>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mapVue from "@/views/component/Map/map.vue"

export default {
    props: ['id'],
    components: {
        vSelect,
        ToastificationContent,
        mapVue,
    },
    data(){
        return {
            address: {
                name: undefined, 
                street_id: undefined,
                home_type_id: undefined,
                lat: undefined,
                lng: undefined,
                is_active: undefined
            },
            home_types: [],
            streets: undefined,
            street_select: undefined,
            submitButtonDisabled: false,
        }
    },
    mounted(){
    },
    methods: {
        opened(){
            this.$http
                .get(`addresses/addresses/${this.id.id}/edit`)
                .then(res => {
                    this.address = res.data
                    this.address.is_active = this.address.is_active == 1 ? true : false
                    this.street_select = res.data.street
            })
            this.searchStreets()
            this.$http
            .get('addresses/home-types')
            .then(res =>{
                this.home_types = res.data.home_types
            })
        },
        searchStreets(streets) {
            setTimeout(() => {
                let filter = streets ? `?filter_name=${streets}&filter_name_condition=startLike` : ''
                this.$http
                    .get(`addresses/streets${filter}`)
                    .then(res => {
                        this.streets = res.data
                })
            }, 300)
        },
        editAddress(){
            this.submitButtonDisabled = true
            this.address.is_active = this.address.is_active == true ? 1 : 0
            let data = {
                name: this.address.name,
                street_id: this.address.street_id.id,
                home_type_id: this.address.home_type_id,
                lng: this.address.lng,
                lat: this.address.lat,
                is_active: this.address.is_active
            }
            this.$http
                .patch(`addresses/addresses/${this.id.id}`, data)
                .then(res => {
                    this.$bvModal.hide('addressModalEdit')
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Успех!',
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: `Адрес успешно изменен!`,
                        },
                    })
                    this.$emit('refresh')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                }).finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        latLng(latLng){
            this.address.lat = latLng.lat
            this.address.lng = latLng.lng
        },
        btnCancel(){
            this.$bvModal.hide('addressModalEdit')
            this.address = {
                name: undefined, 
                street_id: undefined,
                home_type_id: undefined,
                lat: undefined,
                lng: undefined,
                is_active: undefined
            },
            this.home_types = [],
            this.streets = undefined,
            this.street_select = undefined,
            this.submitButtonDisabled = false
        }
    }

}
</script>