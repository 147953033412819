<template>
    <b-modal :id="$route.name + 'CreateModal'" title="Добавить адрес" size="lg" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="opened" class="custom-modal">
        <b-row>
            <b-col cols="12">
                <b-row>
                    <b-col cols="6">
                        <label>Адреc улицы</label>
                        <b-input v-model="address.name" placeholder="Адрес" />
                    </b-col>
                    <b-col cols="6">
                        <label>Выберите улицу</label>
                        <v-select
                            v-model="street_select"
                            @input="setSelected"
                            placeholder="Улица"
                            label="name"
                            :options="streets"
                            class="select-size-md"
                            @search="searchStreets"
                        />
                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col cols="6">
                        <label>Выберите долготу</label>
                        <b-input v-model="address.lng" placeholder="Долгота" />
                    </b-col>
                    <b-col cols="6">
                        <label>Выберите ширину</label>
                        <b-input v-model="address.lat" placeholder="Широта" />
                    </b-col>
                    <b-col cols="6" class="mt-2">
                        <label>Тип вашего дома</label>
                        <v-select
                            v-model="address.home_type_id"
                            placeholder="Тип дома"
                            :options="home_types"
                            label="name"
                            :reduce="option => option.id"
                        />
                    </b-col>
                     <b-col cols="6" class="ml-auto" style="margin-top: 20px;">
                        <b-button class="mt-2" @click="$bvModal.show('mapVue')" size="md" variant="outline-primary" style="width: 215px;" >
                            <feather-icon icon="MapPinIcon" />
                                <span class="ml-1">Выбрать на карте</span>
                        </b-button>
                     </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="d-flex justify-content-between">
                        <b-button
                        :disabled="submitButtonDisabled"
                        class="mt-2 col-md-5"
                        variant="primary"
                        @click="createAddress()">
                        
                            Добавить
                        </b-button>
                        <b-button class="mt-2 col-md-5" variant="secondary" @click="btnCancel">
                            Отмена
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <map-vue @latLng="latLng" />
    </b-modal>
</template>
<script>
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue' 
import mapVue from "@/views/component/Map/map.vue"
export default {
    components: {
        vSelect,
        ToastificationContent,
        mapVue,
    },
    props: [],
    data(){
        return {
            address: {
                name: '',
                street_id: '',
                home_type_id: '',
                lng: '',
                lat: '',
            },
            home_types: [],
            street_select: undefined,
            streets: [],
            submitButtonDisabled: false,
        }
    },
    methods: {
        createAddress(){
            this.submitButtonDisabled = true
            this.$http
                .post('addresses/addresses', this.address)
                .then(res => {
                    this.btnCancel()
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Успех!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `Адрес успешно добавлена!`,
                        },
                    })
                    this.$emit('refresh')
                    this.$bvModal.hide(this.$route.name + 'CreateModal')
                })
                .catch(err => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                        },
                    })
                }).finally(()=>{
                    this.submitButtonDisabled = false
                })
        },
        opened(){
            this.address= {
                name: '',
                street_id: '',
                lng: '',
                lat: '',
                home_type_id: ''
            }
            this.searchStreets()
            this.$http
            .get('addresses/home-types')
            .then(res =>{
                this.home_types = res.data.home_types
            })
            this.clearData()
        },
        searchStreets(streets) {
            setTimeout(() => {
                let filter = streets ? `?filter_name=${streets}&filter_name_condition=startLike` : ''
                this.$http
                    .get(`addresses/streets${filter}`)
                    .then(res => {
                        this.streets = res.data
                })
            }, 300)
        },
        setSelected(){
            this.address.street_id = this.street_select.id
        },
        latLng(newAddress){
            this.address.lat = newAddress.lat
            this.address.lng = newAddress.lng
        },
        btnCancel(){
            this.$bvModal.hide(this.$route.name + 'CreateModal')
            this.clearData()
        },
        clearData(){
            this.address ={
                name: '',
                street_id: '',
                lng: '',
                lat: '',
            },
            this.street_select = undefined,
            this.streets = []
        }
    }
}
</script>

<style>
.custom-modal .modal-dialog {
    max-width: 1200px;
    width: 90% !important;
    height: 900px !important;
}
</style>