<template>
  <div>
    <b-modal 
    id="mapVue" 
    hide-footer 
    no-enforce-focus
    @close="clear()" 
    @show="mapAdd(), division()" 
    content-class="shadow" 
    :no-close-on-backdrop="true"
    title="Карта" 
    size="lg" 
    >
      <div id="map" ref="mapLatLng"></div>
      <b-row>
        <b-col cols="12" :class="address.zoom ? 'd-flex justify-content-between' : 'd-flex justify-content-end'">
              <v-select
                v-if="address.zoom"
                class="mt-2 col-md-5"
                v-model="center"
                :reduce="options => options"
                :options="divisions"
                label="name"
              />
              <b-button @click="editMarker()" class='mt-2 col-md-5' variant="primary">Ок</b-button>
          </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import settingMap from '@/utils/map/settingMap'
import vSelect from 'vue-select'
import {deleteMapContributors} from '@/utils/map/deleteMapContributors'
export default {
  props: {
    address: {
      type: Object,
      default: () => {
        return {
          id: 1,
          lat: "40.28256000",
          lng: "69.62216000",
          name: "Грам (Худжанд)",
          zoom: 14
        }
      }
    }
  },
  components: {
    vSelect
  },
  data() {
    return {
     map: null,
     divisions: [],
     marker: null,
     center: null,
    }
  },
  methods: {
    mapAdd() {
      this.center = this.address
      setTimeout(() => {
        this.map = new maplibregl.Map({
            container: this.$refs.mapLatLng,
            style: settingMap.map.style,
            zoom: settingMap.map.zoom,
            maxZoom: settingMap.map.maxZoom,
            minZoom: settingMap.map.minZoom,
            center: [this.center.lng, this.center.lat],
        });
      deleteMapContributors()
      this.createMarker()
      }, 0)
    },
    division() {
        this.$http
        .get('orders/data')
        .then((res) => {
            this.divisions = res.data.divisions
        })
    },
    createMarker() {
      this.marker = new maplibregl.Marker({draggable: true})
        .setLngLat([this.center.lng, this.center.lat])
        .addTo(this.map);
    },
    clear() {
      if(this.map) this.map.remove()
      if(this.marker) this.marker.remove()
      this.divisions = []
    },
    editMarker() {
      let lngLat = this.marker.getLngLat();
      this.$emit('latLng', lngLat)
      this.$bvModal.hide('mapVue')
      this.clear()
    }
  },
  watch: {
    center(newValue) {
      if(this.map) {
        this.map.jumpTo({'center': [newValue.lng, newValue.lat], 'zoom': settingMap.map.zoom})
        this.marker.remove()
        this.createMarker()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: 500px;
}
</style>