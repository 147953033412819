export function cellSelect () 
{
  let allTd = document.querySelectorAll('td')
  allTd.forEach(el => {
      el.style.backgroundColor = ''
      el.style.color = ''
  })
  let td = event.target
  if (event.target.tagName.toLowerCase() === 'td') {
      td.style.backgroundColor = '#2979ff'
      td.style.color = '#fff'
  } else if(event.target.tagName.toLowerCase() === 'span'){
      let span = event.target
      span.parentNode.style.backgroundColor = '#2979ff'
      span.parentNode.style.color = '#fff'
  }
}